import merge from 'lodash/merge';
import baseConfig from './alpha';

export default merge({}, baseConfig, {
  analytics: {
    shouldTrackEvents: false,
    shouldPrintEvents: false,
    shouldIdentify: false,
  },
  services: {
    segment: {
      shouldLoad: false,
    },
    intercom: {
      isShow: false,
    },
  },
  server: {
    baseUrl: 'https://dev-app.meliopayments.com',
  },
});
